import { useTranslation } from '@dagens/frontend-i18n';
import { useState } from 'react';
import { Box, Form } from '@dagens/carrot';
import {
  useAppDispatch,
  useAppSelector
} from '../../../../_common/hooks/reduxHooks';
import { MinimumOrderPageCallout } from './callout';
import { MinimumOrderForm, MinimumOrderFormValues } from './form';
import PageHeader from '@components/page-header/PageHeader';
import usePageView from '_common/hooks/usePageView';
import { minimumOrderAmountUpdated } from '_common/reducers/auth';
import { Page } from '@components/page';
import { postProducerMinimumOrder } from 'api';
import {
  MINIMUM_ORDER_FORM_PAGE_VIEW,
  MINIMUM_ORDER_SAVED_WITH_FLEXIBLE,
  MINIMUM_ORDER_SAVED_WITH_STRICT,
  track
} from 'utils/mixpanel';
import REQ, { ReqType } from 'utils/REQ';

const MinimumOrderPage = () => {
  const dispatch = useAppDispatch();
  usePageView(MINIMUM_ORDER_FORM_PAGE_VIEW);
  const { producerId, minimumOrderAmount, minimumOrderAmountEnforced } =
    useAppSelector(
      ({
        auth: {
          _id: producerId,
          minimumOrderAmount,
          minimumOrderAmountEnforced
        }
      }) => {
        return {
          producerId,
          minimumOrderAmount,
          minimumOrderAmountEnforced
        };
      }
    );

  const { t } = useTranslation();
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [bottomRef, setBottomRef] = useState<HTMLDivElement | null>(null);

  const onSave = async ({
    minimumOrderAmount,
    minimumOrderAmountEnforced
  }: MinimumOrderFormValues) => {
    setReq(REQ.PENDING);
    const value = minimumOrderAmount ?? 0;
    try {
      await postProducerMinimumOrder({
        userId: producerId,
        producerId,
        minimumOrderAmount: value,
        minimumOrderAmountEnforced
      });
      track(
        minimumOrderAmountEnforced
          ? MINIMUM_ORDER_SAVED_WITH_STRICT
          : MINIMUM_ORDER_SAVED_WITH_FLEXIBLE
      );
      dispatch(
        minimumOrderAmountUpdated({
          minimumOrderAmount: value,
          minimumOrderAmountEnforced
        })
      );
      setReq(REQ.SUCCESS);
    } catch (e) {
      setReq(REQ.ERROR);
    }
  };

  return (
    <Page bottom={<Box.BottomSheet ref={setBottomRef} />}>
      <PageHeader
        headerText={t('producer:MinimumOrderPageHeader')}
        subTitle={t('producer:MinimumOrderPageInfoMessage')}
      />
      <Box.FormWrapper>
        <Form.Section>
          <MinimumOrderPageCallout />
          <MinimumOrderForm
            defaultValue={{
              minimumOrderAmount: minimumOrderAmount ?? null,
              minimumOrderAmountEnforced: minimumOrderAmountEnforced ?? false
            }}
            onSubmit={onSave}
            saveReq={req}
            submitButtonContainer={bottomRef}
          />
        </Form.Section>
      </Box.FormWrapper>
    </Page>
  );
};

export default MinimumOrderPage;
