export const useProducerPlans = (
  transactionCut: number,
  transactionCutForSelfDelivery: number
) => {
  return {
    platformFee: transactionCutForSelfDelivery,
    deliveryFee:
      Math.round((transactionCut - transactionCutForSelfDelivery) * 100) / 100,
    platformFeePercentage: Math.round(transactionCutForSelfDelivery * 100),
    deliveryFeePercentage: Math.round(
      (transactionCut - transactionCutForSelfDelivery) * 100
    )
  };
};
