import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductFormValues } from '../../components/product-form';
import { RootState } from '../../../store';
import { formValuesToProduct } from '../../components/product-form/utils/to-product';
import { uploadImage } from '../../components/product-form/utils/upload-image';
import { ProducerProduct } from '../../../types/Product';
import { ProductFormPricesValues } from '../../components/product-form/edit';
import Logger from '../../../services/Logger';
import { useAppDispatch, useAppSelector } from '_common/hooks/reduxHooks';
import { productEdited as updateProductInStore } from '_producer/reducers/products';
import { postProductFieldsUpdate } from 'api';
import REQ, { ReqType } from 'utils/REQ';

// Save Add product form
// Sends new product to the API
// Refetches products and updates the store
export const useOnSave = (originalProduct: ProducerProduct) => {
  const dispatch = useAppDispatch();
  const [saveReq, setSaveReq] = useState<ReqType>(REQ.INIT);
  const producerId = useAppSelector(({ auth: { _id } }: RootState) => _id);
  const navigate = useNavigate();

  const onSave = useCallback(
    async (data: ProductFormValues & ProductFormPricesValues) => {
      if (saveReq !== REQ.INIT) {
        return;
      }

      try {
        setSaveReq(REQ.PENDING);
        const image = data.image.file
          ? await uploadImage(data.image.file)
          : originalProduct.image;

        const product = formValuesToProduct({
          productId: originalProduct._id,
          data,
          prices: data.prices,
          image
        });

        setSaveReq(REQ.PENDING);
        await postProductFieldsUpdate(product);
        dispatch(
          updateProductInStore({ _id: originalProduct._id, ...product })
        );
        setSaveReq(REQ.SUCCESS);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      } catch (error) {
        Logger.error(error as Error);
        setSaveReq(REQ.ERROR);
      }
    },
    [producerId]
  );

  return { onSave, saveReq };
};
